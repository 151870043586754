/* / font family cdn starts here / */
@import url("https://fonts.cdnfonts.com/css/aileron");
@import url("https://db.onlinewebfonts.com/c/0abeb2471faeb5269db428b9eac2075e?family=GT+Walsheim+Pro+Regular");
@import url("https://db.onlinewebfonts.com/c/0abeb2471faeb5269db428b9eac2075e?family=GT+Walsheim+Pro+black");
@import url("https://fonts.cdnfonts.com/css/capture-it");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");
/* / font family cdn ends here / */
/* / typography starts here / */
@font-face {
	font-family: "GT Walsheim Pro Regular";
	src: url("../../assets/fonts/Walsheim-regular.ttf");
}
@font-face {
	font-family: "gtwm";
	src: url("../../assets/fonts/GTWalsheimPro-Medium.ttf");
}
@font-face {
	font-family: "visaByBold";
	src: url("../../assets/fonts/VisbyBold.otf");
}
@font-face {
	font-family: "gtwb";
	src: url("../../assets/fonts/GTWalsheimPro-Black.ttf");
}
@font-face {
	font-family: "GT Walsheim Pro Black";
	src: url("../../assets/fonts/Walsheim-Black.ttf");
}
@font-face {
	font-family: "halivetic";
	src: url("../fonts/HelveticaNowDisplay-Bold.otf");
}
h1 {
	font-family: "gtwb";
	font-size: 62px;
	font-weight: bold;
	line-height: 1.2;
}
h2 {
	font-family: "gtwb";
	font-size: 42px;
	text-transform: capitalize;
	font-weight: bold;
	line-height: 1.2;
}
h3 {
	font-family: "GT Walsheim Pro Regular";
	font-size: 33px;
	font-weight: bold;
	line-height: 1.2;
}
h4 {
	font-family: "GT Walsheim Pro Regular";
	font-size: 27px;
	font-weight: 600;
	line-height: 1.5;
}
h5 {
	font-family: "GT Walsheim Pro Regular";
	font-size: 22px;
	font-weight: 600;
	line-height: 1.5;
}
h6 {
	font-family: "Poppins";
	font-size: 18px;
	font-weight: 600;
	line-height: 1.5;
}
p {
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 400;
}
/* typography starts here */
/* global css starts here */

html {
	scroll-behavior: smooth;
}
body {
	overflow-x: hidden;
}
button {
	outline: none;
}
.btn {
	font-family: "GT Walsheim Pro Regular";
	background: #1aac7a;
	border-radius: 4px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
	padding: 10px 30px;
}
.btn:hover {
	color: #fff;
	outline: 2px solid #1aac7a;
}
.react-confirm-alert-overlay h1 {
	font-size: 20px;
	color: #1aac7a;
}
/* global css ends here */

/* Prelaoder */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #1aac7a;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #f2f2f2;
	border-top: 6px solid #1aac7a;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* zaam work */
select.goog-te-combo:nth-last-child(1) {
	display: block !important;
}
select.goog-te-combo {
	display: none !important;
}

/* zaam work */

/* Inner Pages Banner Css Start Here */
.universal_banner .banner-content {
	position: relative;
	margin-top: 34px;
}
.universal_banner .banner-content .page-title {
	position: absolute;
	bottom: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 100px;
}
.universal_banner .banner-content .page-title h2.title {
	color: #fff;
	font-weight: 800;
}
.universal_banner .banner-content .banner_image img {
	filter: brightness(0.5);
	/* height: 380px; */
}
/* Inner Pages Banner Css End Here */

/* 404 Not Found Css Start Here */
.page_not_found header#header .main-header-wrapper {
	background: #000;
}
.notFound .pageHeading h2 {
	color: #000;
	text-align: center;
}
section.notFound {
	padding: 200px 0px 50px;
}
/* 404 Not Found Css End Here */

/* Spinner Css Start Here */
.spinner_loading {
	background: #ffffff6b;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 99;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.spinner_loading figure img {
	width: 140px;
}
/* Spinner Css End Here */

/* Header Css */
.appearl_dropwon {
	background: #ffff;
	padding: 50px 50px;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	top: 22%;
	box-shadow: 0px 0px 7px #ddd;
	display: none;
	z-index: 2;
  }
  .appearl_dropwon .appearl-box {
	position: relative;
  }
  .appearl_dropwon .appearl-box .img_box img {
	width: 100%;
  }
  .appearl_dropwon .appearl-box .heading h5 {
	color: #000000;
	font-weight: 600;
	padding-bottom: 25px;
	font-size: 16px;
  }
  .appearl_dropwon .appearl-box .img_box {
	padding-bottom: 25px;
	padding-right: 40px;
  }
  .appearl_dropwon .appearl-box ul.categories {
	padding: 0;
	margin: 0;
	list-style: none;
  }
  .appearl_dropwon .appearl-box ul.categories li a {
	font-size: 14px;
	color: #707070;
	text-decoration: none;
  }
  .appearl_dropwon .appearl-box ul.categories li {
	margin-bottom: 8px;
  }

/* End Header Css  */


/* Search Bar Css Start Here */
.search_box {
	position: absolute;
	width: 100%;
	background: #fff;
	padding: 30px 0 30px;
	top: 0;
	z-index: 9;
	transform: translateY(-180%);
	transition: 0.6s;
}
.search_box.active {
	transform: translateY(0%);
}
.search_box .form-group {
	display: flex;
}
.search_box .form-group input.form-control {
	border-radius: 0;
	font-family: "Poppins";
}
.search_box .form-group button {
	border-radius: 0;
}
header .secondary-header-wrapper {
    /* margin-top: -34px; */
    padding: 0px 150px;
    padding-top: 26px;
}
.search_box .close_btn {
	position: absolute;
	right: 20px;
	top: 20px;
}
.search_box .close_btn button {
	color: #fff;
	background: #000;
	opacity: 1;
	font-size: 16px;
	font-weight: 400;
	width: 40px;
	height: 40px;
}
/* Search Bar Css End Here */

/* header css starts here */

header#header {
	position: unset;
	top: 0;
	width: 100%;
	z-index: 9;
}
header#header .dropdown .dropdown-menu2 {
	min-width: -webkit-fill-available;
	width: 890px;
	padding: 1rem;
}
header#header .navbar-items-wrapper ul li .dropdown .dropdown-menu a {
	/* padding: 0px 1rem; */
	padding: 0 10px;
}
header#header .btn-secondary.focus,
.btn-secondary:focus {
	-webkit-box-shadow: unset;
	box-shadow: unset;
}
header .navbar-items-wrapper ul li a {
	color: #0a0909 !important;
	font-family: "GT Walsheim Pro";
	font-size: 13px;
	background: transparent;
	border: 0;
	align-items: center;
	text-align: center;
}
header .navbar-items-wrapper ul li a:hover {
	border: unset;
	outline: unset;
}
header#header p.mega-menu-head {
	color: #274d5e;
	text-align: left;
	font-family: "GT Walsheim Pro Regular";
	font-size: 16px;
	font-weight: 600;
}
header#header .btn-secondary:hover {
	color: #fff;
	background-color: unset;
	border-color: unset;
}
header#header .dropdown-menu {
    min-width: auto;
    width: 100%;
}
header#header .dropdown-menu.cusdropdown {
	width: 650px;
	padding: 20px 40px;
	margin-top: 50px;
}
header#header .col-7 {
	position: unset !important;
}
header#header .dropdown {
	position: unset !important;
}
header#header .dropdown .btn {
	padding: 10px 0;
	line-height: 0;
	box-shadow: none;
	text-transform: capitalize;
}
header#header .navbar-items-wrapper ul li .dropdown .dropdown-menu a {
	color: #274d5e;
	text-align: left;
	/* margin-left: 2rem; */
	margin-bottom: 0.6rem;
	font-size: 20px;
	text-transform: capitalize;
}
header#header
	.navbar-items-wrapper
	ul
	li
	.dropdown
	.dropdown-menu
	.dropdown-item:hover {
	background-color: unset;
}
header#header .btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: unset;
	background-image: none;
	border-color: unset;
}
header .secondary-header-wrapper {
	background: #ffffff;
	/* padding: 5px 90px; */
}
header .secondary-header-wrapper ul {
	list-style: none;
	display: flex;
	align-items: center;
	margin-bottom: 0px;
	padding: 0px;
}
/* .header-cta-wrapper li:first-child {
	padding: 0px 10px 0px 0px;
	border-right: 1px solid #fff;
} */

.header-cta-wrapper li {
	padding: 0px 10px;
}
.header-cta-wrapper li a i {
	margin-right: 10px;
}
.header-cta-wrapper li a {
	color: #ffffff;
	font-family: "GT Walsheim Pro regular";
	font-size: 12px;
}
.secondary-header-wrapper .social-icons-wrapper ul {
	justify-content: right;
}
.secondary-header-wrapper .social-icons-wrapper ul li {
    padding: 0px 9px;
    font-size: 22px;
}
.secondary-header-wrapper .social-icons-wrapper ul li a {
	color: #fff;
}
.secondary-header-wrapper .social-icons-wrapper select {
	background: transparent;
	color: #fff;
	font-family: "GT Walsheim Pro Regular";
	border: 0px;
}
.social-icons-wrapper i {
	color: #fff;
}
.main-header-wrapper .header-icons-wrapper ul {
	display: flex;
	list-style: none;
	padding: 0px;
	/* justify-content: space-between; */
	align-items: center;
	margin-bottom: 0;
	gap: 15px;
	justify-content: center;
}
.header-icons-wrapper img {
	border-radius: 50%;
	height: 30px;
	width: 30px;
}
.main-header-wrapper .header-icons-wrapper ul li button.search-trigger {
	background: transparent;
	border: 0;
	outline: none;
	padding: 0;
	cursor: pointer;
}
span.total-cart-no {
    background: #45A547;
    width: 15px;
    height: 15px;
    color: #fff;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    line-height: 1.2;
    position: relative;
    left: -8px;
    top: -12px;
    font-size: 10px;
}
.main-header-wrapper {
    padding: 0px 80px 2px;
    border-bottom: 1px solid #ffffff21;
}
.main-header-wrapper .header-icons-wrapper ul i {
	color: #fff;
}
header .navbar-items-wrapper ul {
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 3px;
}
header .navbar-items-wrapper ul li a {
    color: #000;
    font-family: 'GT Walsheim Pro Regular';
    font-size: 16px;
    color: #434343 !important;
    font-weight: 500;
}

.dropdown.health-drop button {
	color: #000;
    font-family: 'GT Walsheim Pro Regular';
    font-size: 16px;
    color: #434343 !important;
    font-weight: 500;
}

.dropdown.health-drop button {
    background-color: transparent;
    border: unset;
    outline: none;
    cursor: pointer;
}
header .container {
	width: 1340px;
}
.mainColumns {
	font-size: 13px;
	text-align: left;
	margin-left: 0;
	letter-spacing: 0;
	line-height: 24px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-height: 450px;
	justify-content: flex-start;
	align-content: flex-start;
	max-width: 100%;
}
.mainColumns .lnkss {
	width: 25%;
	padding: 0.5em;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.para-color .for-para-color {
    font-family: 'Poppins' !important;
    color: #7e7e7e !important;
    font-weight: 400 !important;
}
li.input-wrapper.search input {
    background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
    background: initial;
    border: 0;
    height: 37px;
    outline: none;
    padding-left: 0;
    width: 81%;
}
li.input-wrapper.search input::placeholder {
	padding-left: 5px !important;
	font-size: 12px;
	color: #aeaeae;
}
li.input-wrapper.search button {
	outline: none;
	border: 0;
	position: relative;
	background: transparent;
	height: 35px;
	padding-right: 10px;
	padding-left: 16px;
}

li.input-wrapper.search button img {
    max-width: 20px;
}

li.input-wrapper.search button::before {
	content: "";
	position: absolute;
	display: block;
	width: 1px;
	height: 21px;
	background: #d8d8d8;
	left: 0px;
	top: 8px;
}
li.input-wrapper.search {
	width: 93%;
	border-radius: 25px;
	background: #f3f3f3;
	border-radius: 8px;
	box-shadow: rgb(50 50 93 / 6%) 0px 0 60px -12px inset,
		rgb(0 0 0 / 9%) 3px 20px 4px -16px inset;
}
@media (max-width: 1450px) {
	.main-header-wrapper {
		padding: 0px 50px 0px;
	}
	header .secondary-header-wrapper {
		/* margin-top: -34px; */
		padding: 15px 65px 0;
	}
}
/* header css ends here */
/* banner section css starts here */
.banner {
	background: url(../img/banner-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	/* padding: 250px 0px; */
	padding: 115px 0px;
	/* margin-top: -137px; */
	padding-bottom: 52px;
	position: relative;
	margin-top: 10px;
	background-position: center center;
}
.banner .banner-img-wrapper img {
    height: 430px;
    width: 430px;
    margin: auto;
    object-fit: cover;
    z-index: 9999999 !important;
    position: relative;
}
.banner-content-wrapper.wow.animate__animated.animate__fadeInLeft h5 {
    font-family: 'GT Walsheim Pro Regular';
    font-weight: 100;
}
.banner-slide-wrapper .banner-content-wrapper h1 {
    font-size: 60px;
    width: 467px;
    font-family: "GT Walsheim Pro Regular";
    font-weight: bolder;
}
.banner-slide-wrapper .banner-content-wrapper p {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
}
.banner-slide-wrapper .banner-content-wrapper {
	color: #fff;
}
/* .banner::after {
	content: "Nutri";
	color: #93939359;
	font-family: "Capture it";
	font-size: 200px;
	position: absolute;
	right: 0rem;
	bottom: -3.2rem;
	text-transform: inherit;
} */
/* .banner::before {
	content: "tions";
	color: #93939359;
	font-family: "Capture it";
	font-size: 169px;
	position: absolute;
	left: 0;
	top: 0.5rem;
	text-transform: inherit;
} */
.for-some-dots .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ffffff;
    border: 3px solid transparent;
    border-radius: 20px;
    background: unset;
    font-size: 10px;
    line-height: 5px;
    background: #fff;
}
.for-some-dots .slick-dots li button:before {
    font-family: unset;
    /* font-size: 16px; */
    font-size: 0px;
    width: 14px;
    height: 14px;
    opacity: 1;
    color: #b3adab;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 30px;
}
.slick-dots li {
	margin: 0 0px;
}
/* .slick-dots {
	bottom: -70px;
} */
 .slick-dots {
    position: absolute !important;
    bottom: 12px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.banner::after {
		/* bottom: -53%; */
	}
	header#header .dropdown .dropdown-menu2 {
		width: 810px;
	}
	.banner {
		/* bottom: -53%; */
	}

	header .navbar-items-wrapper ul li a {
		font-size: 12px;
		
	}
	.banner-slide-wrapper {
		padding-top: 30px;
	}
	.banner {
		padding: 0px 0px;
	}
	.btn {
		
		font-size: 12px;
		padding: 12px 37px;
	}
	.slick-dots {
		bottom: 10px;
	}
	.div-heding h3 {
		font-size: 16px !important;
		white-space: nowrap;
	}
	.div-ul ul li {
		font-size: 13px;
	}
	.dropdown.health-drop button {
		font-size: 12px !important;
	}
}

@media (max-width: 1366px) {
	.banner::after {
		/* bottom: -53%; */
	}
	header#header .dropdown .dropdown-menu2 {
		width: 810px;
	}
	.banner {
		/* bottom: -53%; */
	}

	header .navbar-items-wrapper ul li a {
		font-size: 12px;
		
	}
	.banner-slide-wrapper {
		padding-top: 30px;
	}
	.banner {
		padding: 0px 0px;
	}
	.btn {
		
		font-size: 12px;
		padding: 12px 37px;
	}
	.slick-dots {
		bottom: 10px !important;
	}
	.div-heding h3 {
		font-size: 12px !important;
		white-space: nowrap;
	}
	.div-ul ul li {
		font-size: 11px;
	}
	.dropdown.health-drop button {
		font-size: 12px !important;
	}
}
/* responsive ends here */
/* banner section css ends here */
/* product card css starts here */
.product-card {
    padding: 40px 0px 50px;
    background-color: #f8f8f8;
}
.product-card2 {
    padding: 46px 0px 60px;
    background-color: #f8f8f8;
}
.product-card .product-card-wrapper {
	display: flex;
	align-items: center;
	background: #bff8e5;
	box-shadow: 3px 4px 9px 2px #cacacaa8;
	transition: all 0.3s ease-in-out;
	margin: 10px 12px;
}
.product-card-wrapper img {
	width: 80px;
	object-fit: contain;
	height: 97px;
}
.product-card .product-card-content-wrapper h6 {
	color: #00462e;
	font-family: "GT Walsheim Pro regular";
	font-size: 14px;
	margin-bottom: 0;
}
.product-card .product-card-content-wrapper span {
	font-family: "GT Walsheim Pro regular";
	font-size: 13px;
	color: #61a08c;
}
.product-card .product-card-wrapper:hover {
	background: #1aac7a;
}
.product-card .product-card-wrapper:hover h6 {
	color: #ffffff;
}
.product-card .product-card-wrapper:hover span {
	color: #ffffff;
}
/* product card css ends here */
/* about us section starts here */
.call-btn {
	position: absolute;
	display: flex;
	align-items: center;
	background: #fff;
	box-shadow: 3px 3px 10px 1px #00000024;
	border-radius: 10px;
	width: 44%;
	bottom: 6%;
	right: 7%;
}
.special-offer .single-product-card-wrapper a.btn.form-control {
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: -9px;
	right: -11px;
	width: 66px;
	border-radius: 77%;
	height: 62px;
	align-items: center;
	outline: 7px solid #afe2d0;
	border: 2px solid #fff;
}

.special-offer .single-product-card-wrapper {
	position: relative;
	overflow: hidden;
}
.call-btn .content {
	margin-left: 10px;
}
.call-btn .content h4 {
	margin: 0px;
	color: #274d5e;
	font-family: "Poppins";
	font-size: 16px;
}
.about-img-wrapper {
	position: relative;
}

/* Header Translate */
.goog-te-gadget {
	font-size: 0;
}
.goog-te-gadget a.goog-logo-link {
	display: none;
}
.goog-te-gadget select.goog-te-combo {
    border: 2px solid #45a547;
    outline: none;
    border-radius: 7px;
    color: #434343;
    font-family: "Lato-Regular";
    padding: 0.4rem;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    /* color: #ffffff; */
}
.goog-te-gadget select.goog-te-combo option {
	color: #000;
}

.goog-te-gadget {
	font-size: 0 !important;
}

/* Header Translate */

.call-btn h6 {
	margin-bottom: 0px;
	color: #79797a;
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 500;
}
.About-us {
	padding-top: 50px;
}
.About-us h6 {
	color: #1aac7a;
	font-weight: 500;
	font-size: 14px;
}
.About-us h2 {
	color: #274d5e;
	font-size: 32px;
	font-family: "gtwb";
}
.About-us p {
	color: #7d7d7d;
	font-weight: 400;
	font-size: 13px;
}
.About-us .about-quote-wrapper::after {
	content: url(../img/pill.svg);
	position: absolute;
	left: 8%;
	bottom: -46%;
	z-index: -1;
}
.about-quote-wrapper {
	background: #1aac7a;
	position: relative;
}
.about-quote-wrapper p {
	color: #fff;
	margin-bottom: 0px;
	padding: 20px 10px;
}
.About-us .john-wrapper {
	margin-top: 51px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #616161;
	padding-bottom: 17px;
}
.About-us .john-wrapper figure {
	margin-bottom: 0px;
}
.About-us .john-wrapper img {
	border: 3px solid #1aac7a;
	border-radius: 52%;
}
.About-us .john-name-wrapper h6 {
	color: #08094d;
	font-size: 18px;
	font-family: "Poppins";
	font-weight: 600;
	margin-bottom: 0px;
}
.About-us .john-name-wrapper span {
	color: #1aac7a;
	font-size: 14px;
	font-family: "Poppins";
	font-weight: 500;
}
.john-wrapper .john-name-wrapper {
	margin-left: 10px;
}
section.About-us .about-tabs-wrapper {
	margin-top: 16px;
}
.About-us .nav-tabs {
	border-bottom: 0px;
}
.About-us .nav-tabs .nav-link.active {
	border: 0px;
	font-size: 20px;
	font-family: "Poppins";
	font-weight: 600 !important;
	border-bottom: 3px solid #1aac7a;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	color: #1aac7a !important;
}
.About-us #myTabContent p {
	margin-top: 20px;
}
.About-us .about-tabs-wrapper .nav-tabs .nav-link {
	color: #274d5e;
	font-size: 20px;
	font-weight: 600;
}
/* about us section ends here */
/* featured product section starts here */
.featured-products {
	padding: 80px 0px;
}
.featured-products h2 {
	color: #005739;
	font-size: 42px;
	font-family: "gtwb";
}
.single-product-card-wrapper {
	/* box-shadow: 3px 3px 10px 1px #00000024; */
	box-shadow: 0px 3px 5px -1px #00000024;
	padding: 20px;
	margin: 20px 0px;
}
.featured-products a {
	text-decoration: none;
	color: #ccc;
}
.single-product-card-wrapper a {
	text-decoration: none;
	color: #1aac7a;
	font-family: "GT Walsheim Pro Regular";
}
.single-product-card-wrapper a {
	text-decoration: none;
}
.single-product-card-wrapper .review-wrapper ul {
	padding: 0px;
	display: flex;
	list-style: none;
	margin: 0;
}
.single-product-card-wrapper .review-wrapper ul li {
	line-height: 17px;
}
.single-product-card-wrapper .review-wrapper ul li i {
	color: #ebbc00;
	margin: 0px 5px 0px 0px;
}
.single-product-card-wrapper .review-wrapper ul li span {
	color: #67828e;
	font-family: "GT Walsheim Pro Regular";
	font-size: 14px;
	margin-left: 12px;
}
.cart-button-wrapper a.btn i {
	margin-right: 23px;
}

.reviewsSize {
	font-size: 12px;
	color: #989898;
}

.btnColor {
	background-color: #44a548;
	color: #fff;
}
.btnColor2 {
	background-color: #fff;
	color: #44a548 !important;
}
.single-product-card-wrapper h5.single-product-name {
    color: #000000d6;
    margin: 8px 0;
    font-family: 'gtwm';
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    min-height: 40px;
    /* font-family: 'GT Walsheim Pro Regular'; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}
.single-product-content-wrapper p {
	text-align: left;
	font-size: 12px;
	color: #44a548;
	font-weight: bold;
}
.WrapperCardDiv {
    box-shadow: 0px 0px 20px #DBDBDB;
    border-radius: 10px;
    background-color: #ffffff;
}
.single-product-card-wrapper .price-wrapper {
	margin-top: 10px;
}
.single-product-card-wrapper h6.single-product-category {
	color: #1aac7a;
	font-size: 15px;
}
.single-product-card-wrapper .price-wrapper h6.price {
	font-family: "GT Walsheim Pro Regular";
	color: #44a548;
	text-align: left;
}
.single-product-card-wrapper .price-wrapper h6.price span {
	color: #989898;
	text-decoration: line-through;
	font-size: 14px;
	margin-left: 12px;
}
p.total-result {
	font-family: "GT Walsheim Pro Regular";
	font-size: 15px;
	font-weight: 500;
}
.single-producrt-img-wrapper img {
	height: 173px;
	width: 100%;
	object-fit: contain;
}
/* featured product section ends here */
/* special offer starts here */
.special-offer {
	padding-bottom: 60px;
}
section.special-offer a {
	text-decoration: none;
}
.special-offer .product-card-wrapper {
	display: flex;
	align-items: center;
	background: #1aac7a;
	box-shadow: 3px 4px 9px 2px #cacacaa8;
	transition: all 0.3s ease-in-out;
	box-shadow: 0px 3px 5px 0px #0000002b;
}
.special-offer .product-card-wrapper {
	background: #1aac7a;
	margin: 16px 0px;
	/* padding: 1rem 1rem 1rem 0; */
	padding: 5px 1rem 5px 10px;
}
.product-card .product-card-wrapper img {
	width: 70px;
	object-fit: contain;
	height: 95px;
	margin-right: 10px;
	mix-blend-mode: darken;
}
.product-card-wrapper figure {
	margin: 0;
}
.special-offer .product-card-content-wrapper h6 {
	color: #00462e;
	font-family: "GT Walsheim Pro regular";
	font-size: 20px;
}
.special-offer .product-card-content-wrapper span {
	font-family: "GT Walsheim Pro regular";
	font-size: 14px;
	color: #61a08c;
}
.product-card a {
	text-decoration: none;
}
.product-card .product-card-wrapper {
	display: flex !important;
	align-items: center;
	background: #bff8e5;
	box-shadow: 3px 4px 9px 2px #cacacaa8;
	transition: all 0.3s ease-in-out;
	margin: 10px 12px;
	width: unset !important;
}
.special-offer .product-card-wrapper .product-card-content-wrapper {
	padding-left: 8px;
}
.special-offer .product-card-wrapper h6 {
	color: #ffffff;
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
}
.special-offer .product-card-wrapper span {
	color: #ffffff;
}
section.special-offer .rating ul {
	padding: 0px;
	list-style: none;
	display: flex;
	align-items: center;
}
section.special-offer .rating ul li span {
	margin-left: 20px;
}
section.special-offer .rating ul li {
	margin: 0px 4px 0px 0px;
}
section.special-offer .rating ul li i {
	font-size: 11px;
	color: #ebbc00;
}
section.special-offer span.sale-price {
	font-size: 18px;
	font-weight: bold;
}
.special-offer span.reg-price {
	color: #bae6d7;
	text-decoration: line-through;
	margin-left: 26px;
}
.special-offer .rating ul {
	margin: 0px !important;
}
.offer-bg-wrapper img {
	/* width: 26rem; */
	/* max-width: 26rem; */
	height: 410px;
	margin: 0 auto;
	/* margin-left: -2.7rem; */
	margin-bottom: 1.5rem;
}
.offer-bg-wrapper {
	background: url("../img/offer-bg.png");
	margin-top: 12px;
	/* padding: 50px 0px; */
	background-size: cover;
	background-repeat: no-repeat;
	/* height: 437px; */
	box-shadow: 0px 3px 5px 0px #00000024;
}
.special-offer h2 {
	color: #274d5e;
	font-family: "gtwb";
}
.special-offer .single-product-card-wrapper {
	box-shadow: 0px 3px 5px 0px #00000024;
	/* padding: 10px; */
	padding: 1rem;
	margin: 20px 35px;
	margin-top: -60px;
	background: #fff;
	border-radius: 3px;
}
/* special offer ends here */
/* winter section starts here */
.winter-sec .winter-card-wrapper {
	display: flex;
	align-items: center;
	padding: 39px 39px;
	background: url(../img/Get-Set-For-Winter-bg.png);
	border-radius: 7px;
	/* box-shadow: 3px 6px 6px 2px #0000002b; */
	background-position-x: center;
	background-repeat: no-repeat;
	/* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); */
	/* -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); */
	/* -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); */
	box-shadow: 0px 2px 5px 0px #0000002b;
	background-size: 100% 100%;
}
.winter-sec {
	padding-bottom: 0px;
	padding-top: 54px;
}
.winter-card-wrapper h2 {
    font-size: 46px;
    font-family: "gtwb";
    font-weight: bold;
    color: #3b3b3b;
}
.winter-card-wrapper h6 {
    color: #44a548;
    font-weight: 700;
}
.winter-card-wrapper p {
    color: #7e7e7e;
    font-weight: 500;
    font-size: 13px;
}
.proBannerbtn .winter-sec .winter-card-wrapper .card-content-wrapper {
	flex: 8;
}
.winter-sec .winter-card-wrapper .card-img-wrapper {
	flex: 4;
}
.winter-sec .sports-card-wrapper {
    display: flex;
    background: #44a548;
    padding: 25px 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px 1px #dcdcdc;
    height: 209px;
}
.sports-img-wrapper img {
    width: 90%;
}
.sports-card-wrapper h2 {
	color: #fff;
	/* text-shadow: 0px 0px 3px #000000ab; */
	font-size: 28px;
	font-family: "GT Walsheim Pro regular";
}
.sports-card-wrapper h6 {
	color: #ffffff;
}
.sports-card-wrapper a.btn {
	background: #fff;
	color: #1aac7a !important;
}
.winter-sec .herb h2 {
    color: #fff;
    font-family: halivetic;
    font-size: 25px;
    text-shadow: 0 0px 0px #000000b3;
}
.sports-card-wrapper.herb {
	background-image: url(../img/Get-Set-For-Winter-bg.png);
	/* background-position-y: 10%; */
	/* background-position-x: -38px; */
	background-position-x: 100%;
	margin: 27px 0px;
	background-size: contain;
	background-repeat: no-repeat;
	background-size: 400px;
	/* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); */
	/* -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); */
	/* -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); */
	/* box-shadow: 0px 2px 5px 0px #0000002b; */
}
/* .sports-card-wrapper .sports-img-wrapper::after {
  content: url("../img/sport-nutrition.png");
  transform: scale(0.8);
  position: absolute;
  top: -121px;
  right: -26px;
} */
/* winter section ends here */
/* testimonails starts here */
.sport_wrapper .sports-img-wrapper img {
    transform: scale(0.8);
    position: absolute;
    top: -117px;
    right: -23px;
    width: 94%;
}
.sport_wrapper .sport-content-wrapper h2 {
    font-family: halivetic;
    font-size: 27px;
    padding-right: 190px;
    text-shadow: 100 66 #000;
    text-shadow: 0 0px 0px #000000b3;
}
.testimonails {
	padding: 40px 0px;
	/* background-image: url(../img/testimonail-bg.png); */
	background-repeat: no-repeat;
	background-position: top;
}
.testimonails-wrapper .rating-wrapper ul {
	padding: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
}
.testimonails-wrapper .rating-wrapper ul li:last-child {
	margin: 0;
}
.testimonails-wrapper .rating-wrapper ul li {
	margin: 0px 8px 0px 0px;
	color: #ffd400;
}
.testimonails-wrapper {
	background: #fff;
	padding: 18px;
	box-shadow: 0px 0px 9px 3px #bbbbbb26 !important;
	box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 16%);
	/* -webkit-box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 16%); */
	-moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.16);
	margin-top: 44px;
	margin-bottom: 5px;
	width: 724px !important;
	margin-left: 5px;
	transform: scale(0.9);
}
/* .testimonails-wrapper {
  background: #fff;
  padding: 18px;
  box-shadow: 0px 0px 10px 2px #00000017;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
-webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
-moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
margin-top: 44px;
margin-bottom: 5px;
width: 724px !important;
margin-left: 5px;
} */
.testimonails .slick-prev:before {
	content: "\f104";
	font-family: "FontAwesome";
	background: url(../img/arrow-bg.png);
	padding: 24px 29px 28px 26px;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 1;
	background-position: center;
}
.testimonails .slick-prev {
	left: -8%;
}
.testimonails .slick-next:before {
	content: "\f105";
	font-family: "FontAwesome";
	background: url(../img/arrow-bg.png);
	padding: 24px 29px 28px 26px;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 1;
	background-position: center;
	z-index: 9;
}
.testimonails .slick-next {
	right: -4%;
	z-index: 99;
}
.testimonails-slider::after {
	content: url(../img/Union.png);
	position: absolute;
	right: 10%;
	top: 0px;
}
.testimonails-slider::before {
	content: url(../img/Union.png);
	position: absolute;
	left: 10%;
	top: 0px;
	z-index: 99;
}
.testimonails-wrapper p {
	font-family: "Poppins";
	color: #848484;
	font-size: 14px;
}
.testimonails-wrapper h6 {
	font-family: "Montserrat";
	color: #044e1f;
}
.testimonails h2 {
	color: #005739;
	font-family: "gtwb";
}
.testimonails-wrapper img {
	margin-top: -75px !important;
	margin: 0px auto;
	width: 150px;
	border-radius: 50%;
	height: 150px;
}
/* testimonails ends here */
/* blog section css starts here */
.blog {
    padding-bottom: 62px;
    background-color: #ffffff !important;
}
.blog h2 {
	color: #274d5e;
	font-family: "gtwb";
}
.blo-date-and-authar-wrapper ul {
    display: flex;
    padding: 0px;
    list-style: none;
    align-items: inherit;
}
.blo-date-and-authar-wrapper ul li {
	padding: 0px 8px 0px 10px;
}
.blo-date-and-authar-wrapper ul li:first-child {
	border-right: 2px solid #00234b;
}
.blo-date-and-authar-wrapper ul li i {
	color: #1aac7a;
	margin-right: 7px;
}
.blo-date-and-authar-wrapper ul li a {
    color: #3b3b3b;
    font-family: "visaByBold";
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.blog-card-wrapper .blog-content-wrapper {
    padding: 0px;
}
.blog-card-wrapper .blog-content-wrapper p {
	color: #003e4b;
	font-family: "GT Walsheim Pro Regular";
	font-size: 14px;
	font-weight: 500;
}

.tilteset {
	color: #003e4b;
}

.name a:hover {
	text-decoration: none;
}

.blog-card-wrapper .blog-img-wrapper img {
	height: 250px;
	object-fit: cover;
	width: 100%;
	border-radius: 10px;
}

/* .hover_deee {
	position: absolute;
	width: 0;
	height: 0;
	background: #1aac7a6e;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	top: 7px;
	margin: 0px auto;
	transform: rotate(360deg);
	transition: 0.7s;
} */

.btnBlogs {
	font-family: "GT Walsheim Pro Regular";
	background: #434343;
}
.blog-img-wrapper:hover .hover_deee {
	height: 49%;
	width: 90%;
	transform: rotate(0deg);
}

/* blog section css ends here */
/* newsletter starts here */
.newsletter .newsletter-bg {
	background: url("../img/newsletter-bg.png");
	padding: 30px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding-top: 26px;
    padding-bottom: 50px;
    align-items: center;
    padding-bottom: 26px !important;
    width: 90%;
    margin: 0 auto;
}

.newsletter {
    margin-bottom: -90px;
}
.newsletter-input-wrapper {
	background: #fff;
	padding: 10px;
	display: flex;
	border-radius: 5px;
}
.newsletter-input-wrapper input {
	border: 0px;
	font-family: "GT Walsheim Pro regular";
}
.newsletter-input-wrapper button {
    font-family: "GT Walsheim Pro regular";
    font-weight: 500;
    border-radius: 5px;
    padding: 12px 55px;
}

.content-wrapper p {
    font-size: 16px;
    color: #ffffff !important;
    font-family: 'GT Walsheim Pro Regular' !important;
    font-family: 'Poppins';
    font-weight: 400;
}
.newsletter .content-wrapper {
	color: #fff;
}

.newsletter .content-wrapper h6 {
    font-family: "GT Walsheim Pro regular";
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 4px;
}

.newsletter .content-wrapper h3 {
    font-size: 31px;
    font-family: "GT Walsheim Pro regular";
    font-weight: 500;
}

.newsletter .content-wrapper p {
    font-family: "GT Walsheim Pro regular";
    font-weight: 500;
    line-height: 20px;
}
/* newsletter ends here */

/* Home Category Section start here */
/* .mainContainerCate {
	background-color: #f8f8f8;
} */

.addParaCate {
	margin: 0;
}

.first_section_heading {
	display: flex;
	align-items: end;
	margin-bottom: 40px;
}
.first_section_heading .first_section_btn {
	background: #44a548;
	color: #fff;
	font-family: "Poppins";
	font-weight: 600;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	/* padding: 12px 30px; */
	margin-top: 10px;
	text-align: center;
	line-height: 40px;
}

.first_section_para .Cate {
    font-size: 30px;
    font-family: 'gtwb';
    color: #434343;
    margin: 0px 0px;
    margin-left: 10px;
    font-weight: 900;
}

.some-css-for-upper {
    padding-top: 80px;
}
.first_section_category ul {
	padding: 0;
}

.first_section_category ul li span p {
    margin-left: 15px;
    color: #777777;
    font-family: "gtwm";
    font-style: italic;
    font-weight: 500;
    font-size: 17px;
    line-height: 50px;
}

.first_section_category ul li span i {
	color: #777777;
	font-size: 13px;
}

.first_section_category ul li {
	display: flex;
	align-items: center;
	justify-self: center;
	margin-top: 6px;
}
.first_section_category ul li .addIconCate {
	background: #f3f3f3;
	color: #fff;
	font-family: "Poppins";
	border-radius: 50%;
	width: 31px;
	height: 31px;
	/* margin-top: 10px; */
	text-align: center;
	line-height: 31px;
}

.mainContainerCate .HomeCateCard {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.double-product-card-wrapper {
    box-shadow: 0px 3px 11px 1px #DCDCDC;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 8px;
    background-color: #ffffff;
}
.Cate_div_center {
	/* padding: 40px; */
}
.form-control2 {
	background-color: #44a548;
}
/* Home Category Section end here*/

/* Health & Wellness here start */

.Health-card {
	padding: 50px 0px 50px;
}

.heath_wellness_main .health_div_flex {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	justify-content: space-between;
}

.health_first_heading .Heading_pro {
    color: #434343!important;
    font-family: gtwb!important;
    font-size: 33px;
    font-weight: 900;
    margin-bottom: 0px;
    padding-bottom: 30px;
    padding-top: 0;
}

.health_first_heading .healthBtn {
    width: 25%;
    margin: 10 auto;
    margin: 20px;
    padding: 12px;
}

.health_first_heading {
	text-align: center;
}
.Main_card_div_health .back_card_title {
	margin: 15px;
}

.Main_card_div_health .back_card_title p {
    color: #44a548;
    font-family: poppins;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
}
.health_card_img {
    background-color: #fff;
    box-shadow: 0px 2px 5px 1px #0000003d;
    padding: 20px;
    width: 252px !important;
    height: 190px;
    border-radius: 10px;
}
.health_card_img img {
	width: 80%;
}
/* Health & Wellness  here end*/
/* SliderCard  here start*/
.sliderCardSet {
    box-shadow: 0px 3px 8px 2px #e5e5e5;
    padding: 20px;
    margin: 27px 0px;
    border-radius: 8px;
    width: 256px;
    margin-left: 5px;
    background-color: #ffffff;
}
/* SliderCard  here end*/

/* BestSell  here start*/
.bestSellCard {
	width: 100%;
	border-radius: 10px;
	height: 97%;
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.bestSellCard .bestTopSec img {
	width: 550px;
	height: 500px;
}

.bestSellCard .cardTitleSection {
	display: flex;
	width: 538px;
	justify-content: space-between;
	text-align: center;
	background-color: #ffffff;
}

.card-body .card-text h4 {
	font-family: "poppins";
	font-size: 25px;
	font-weight: bold;
	color: #3b3b3b;
}

.card-body .card-text p {
	color: #7e7e7e;
	font-family: "poppins";
	font-size: 15px;
}

.card-body .dicountPrice {
	position: absolute;
	top: 340px;
	left: 20px;
}

.secondCard {
	height: 94%;
}

.secondCard .dicountPrice {
	position: absolute;
	top: 100px;
	left: 20px;
}

.secondCard .dicountPrice .setDicAmount img {
	width: 90px;
	height: 90px;
}

.secondCard .dicountPrice .setDicAmount .priceDisSell h3 {
	font-size: 20px;
	font-family: "GT Walsheim Pro";
	position: absolute;
	top: 0px;
	left: -10px;
}

.secondCard .dicountPrice .setDicAmount .priceDisSell span {
	font-size: 15px;
	font-family: "GT Walsheim Pro";
	position: absolute;
	top: 20px;
	left: 20px;
}

.secondCard .productImageBestsell img {
	width: 70%;
	height: 60%;
}

.secondCard .productImageBestsell {
	position: absolute;
	top: 50px;
	right: -50px;
}

.dicountPrice .setDicAmount {
	position: relative;
}

.setDicAmount img {
	width: 130px;
}

.setDicAmount .priceDisSell {
	position: absolute;
	top: 30px;
	left: 30px;
	z-index: 999;
	color: #fff;
	font-family: "GT Walsheim Pro";
	font-size: 20px;
	font-weight: bold;
}
.setDicAmount .priceDisSell span {
	font-size: 23px;
	position: absolute;
	top: 35px;
	left: 22px;
	font-family: "GT Walsheim Pro";
}
.productImageBestsell {
	position: absolute;
	top: 280px;
	right: 0px;
}
.bestSellCard .card-body .card-text,
.secondCard .card-body .card-text {
	margin-left: 130px;
}

.bestSellCard img,
.secondCard img {
	border-radius: 10px 10px 0px 0px;
}

.secondCard img {
	height: 150px;
}

.secondCard {
	width: 100%;
	border-radius: 10px;
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.blog svg {
    margin-right: 10px;
}

/* .cardTitleSection .productImageBestsell {
	position: absolute;
	top: 15px;
	left: 15px;
} */
/* BestSell  here end*/

/* footer css starts here */
.footer {
    background: transparent;
    padding-top: 200px;
    box-shadow: inset 0px 0px 32px #e1e1e1;
}
footer ul {
	list-style: none;
	padding: 0px;
	margin: 0;
}
footer.footer h5 {
    color: #434343;
    margin-bottom: 18px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 700;
    white-space: nowrap;
}
.footer-1-content-wrapper {
	display: flex;
	justify-content: space-between;
	position: relative;
	align-items: center;
	height: 100%;
}
.footer-1-content-wrapper img {
	padding-right: 31px;
}
.footer-links-wrapper li {
    line-height: 24px;
}
.footer-links-wrapper li a {
    color: #00301f;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 400;
    white-space: nowrap;
}
.footer-1-content-wrapper {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.footer-links-wrapper li a i {
	width: 19px;
}
.footer-1-content-wrapper p {
	font-family: "GT Walsheim Pro regular";
	margin-top: 10px;
}
.footer-1-content-wrapper .borderLeftFooter {
	/* border-right: 1px solid #659194; */
	padding: 0 1rem;
}
.footerline::after {
    content: " ";
    width: 1px;
    height: 199px;
    color: #000;
    background: #0000005c;
    position: absolute;
    right: 0;
    top: -20px;
}
.footer-widget-2 {
    /* border-right: 1px solid #659194; */
    padding: 0 0px;
    position: relative;
}
.footer-widget-3 {
    /* border-right: 1px solid #659194; */
    padding: 0 0;
    position: relative;
}
.footer-widget-4 {
	padding: 0 1rem;
}
.footer-links-wrapper ul.social-links li {
	margin: 30px 21px 0px 0px;
	color: #434343;
}

.footer-links-wrapper ul.social-links li a {
	font-size: 20px;
	color: #434343;
}
.copy-right-wrapper h6 {
	font-size: 12px;
	text-align: end;
	/* line-height: 2; */
}
.copy-right-wrapper h6 a {
	color: #274d5e;
	font-family: "GT Walsheim Pro regular";
}
.copy-right-wrapper p {
    font-size: 12px;
    margin-bottom: 0px;
    font-size: 12px;
    font-family: "GT Walsheim Pro regular";
    color: #434343 !important;
    font-weight: 400;
    /* letter-spacing: 1px; */
}
.copy-right-wrapper {
	padding: 20px 0px;
	border-top: 1px solid #aadcd1;
}
.copyRightCenter {
	display: flex;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}
/* footer css ends here */
.single-product-card-wrapper a {
	color: #1aac7a;
	font-family: "GT Walsheim Pro black";
}
.category_box .cat-card {
	background: #fff;
	box-shadow: 0px 0px 8px #ccc;
	margin: 15px 0px;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.6s;
}
.category_box .cat-card .title h5 {
	font-size: 18px;
	color: #1b977a;
	transition: 0.6s;
}
.category_box a {
	text-decoration: none;
}
.category_box .cat-card:hover {
	background: #1a9678;
}
.category_box .cat-card:hover .title h5 {
	color: #fff;
}
.category_box button {
	width: 100%;
	margin: 0px 0px;
	background: transparent;
	border: 0;
	padding: 0;
	outline: none;
	box-shadow: none;
	cursor: pointer;
}
header#header .navbar-items-wrapper ul span.sub-child {
	color: #274d5e;
	text-align: left;
	/* margin-left: 2rem; */
	margin-bottom: 0.6rem;
	font-size: 12px;
	padding-left: 10px;
	text-transform: capitalize;
}
header#header .navbar-items-wrapper ul .lnk {
	margin: 8px 0;
}
header#header .dropdown-menu.cusdropdown::-webkit-scrollbar {
	width: 0.2em !important;
}
header#header .dropdown-menu.cusdropdown::-webkit-scrollbar-thumb {
	background-color: darkgrey !important;
}
header#header .dropdown:hover .dropdown-menu {
	display: block;
	position: absolute;
	top: 32px !important;
	transform: none !important;
	will-change: unset !important;
	/* display: none; */
}
.Customdrop {
	pointer-events: none;
}
.dropdown-menu.cusdropdown.show {
	top: -46px !important;
	left: -1px !important;
	transform: none !important;
	will-change: unset !important;
}
section.categories .card {
	border-radius: 0;
	border: 2px solid #1d997b;
}
section.categories .card .card-header {
	border: 0;
	padding: 20px 20px;
	background: transparent;
	border-radius: 0;
}
section.categories .card .card-header button {
	font-size: 16px !important;
}
.categories .card .card-body {
	padding: 20px 20px !important;
}
.categories .card .card-body .subCat {
	border-bottom: 1px solid #ddd !important;
	padding: 10px 0 !important;
}
header .secondary-header-wrapper li {
	display: flex;
	align-items: baseline;
	justify-content: space-around;
}

/* header .secondary-header-wrapper li span {
	display: none !important;
} */
header .secondary-header-wrapper li .skiptranslate.goog-te-gadget {
	font-size: 0;
}
.logo-wrapper .logo_image {
	width: 100%;
	height: 76%;
	object-fit: contain;
}
header
	.secondary-header-wrapper
	li
	div#google_translate_element
	select.goog-te-combo
	option {
	color: #000;
}
header
	.secondary-header-wrapper
	li
	div#google_translate_element
	select.goog-te-combo {
	outline: none;
	box-shadow: none;
}
.categories .card .card-body {
	padding: 0 !important;
}
.categories .card .card-body .MuiButtonBase-root .sidebar-content-wrapper {
	background: transparent !important;
}
.categories
	.card
	.card-body
	.MuiAccordionDetails-root
	.sidebar-content-wrapper {
	padding-left: 50px !important;
}
.categories .card .card-body .MuiAccordionDetails-root p {
	margin: 7px 0px !important;
	border: 0 !important;
}
.categories .card .card-body .sidebar-content-wrapper::-webkit-scrollbar {
	width: 0.3em !important;
}
.categories .card .card-body .sidebar-content-wrapper::-webkit-scrollbar-thumb {
	background-color: #1d997b !important;
}
.categories .card .card-body.innerStep {
	padding: 0 20px !important;
}
.categories .card .card-body.innerStep .MuiPaper-root {
	border: 0;
}
.sidebar-content-wrapper span.MuiSlider-thumbColorPrimary {
	color: #fff !important;
	box-shadow: 0px 0px 3px #000 !important;
}

/* Best Seller Css Start Here */
.best-seller .first-box {
	height: 100%;
}
.best-seller .product-box .frst-img img {
	height: 450px;
	width: 100%;
	object-fit: cover;
	border-radius: 15px 15px 0px 0px;
}
.product-box.for-img .nutrient img {
	width: 83px !important;
}
.best-seller .product-box figure {
	margin: 0;
}
.best-seller .product-box .off {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	bottom: 70px;
	padding: 0 70px 0 30px;
}
.best-seller .product-box .off .off-box {
	background: #44a548;
	width: 140px;
	height: 140px;
	border-radius: 50%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.best-seller .product-box .off .off-box h3 {
	color: #fff;
	line-height: 29px;
	font-size: 33px;
	margin: 0;
}
.best-seller .product-box .off .off-box h3:first-child {
	font-size: 40px;
	line-height: 35px;
}
.best-seller .product-box .off .off-box h3:last-child {
	margin-left: 25px;
}
.best-seller .product-box .content-wrapper {
    background: #fff;
    box-shadow: 0px 3px 10px 1px #ddd;
    padding: 20px 126px;
    text-align: initial;
    border-radius: 0px 0px 15px 15px;
}
.best-seller .product-box .content-wrapper h4 {
	margin: 0;
}
.best-seller .product-box .content-wrapper p {
	margin: 0;
}
.best-seller .first-box {
	height: 100%;
}
.best-seller .second-box .off .off-box {
	width: 90px;
	height: 90px;
	margin-bottom: -30px;
}
.best-seller .second-box .off .off-box h3 {
	font-size: 20px;
	line-height: 20px;
}
.best-seller .second-box .off .off-box h3:first-child {
	font-size: 25px;
	line-height: 20px;
}
.best-seller .second-box .nutrient figure {
	position: relative;
	bottom: 20px;
	left: 20px;
}
.best-seller .second-box .img-box img {
	height: 170px;
}
.best-seller .second-box .nutrient img {
	width: 70%;
	margin: 0px 0 0 auto;
	display: table;
}
.best-seller .third-box {
	position: relative;
}
.best-seller .third-box .img-box img {
	height: 180px;
}
.best-seller .third-box .off .off-box {
	width: 70px;
	height: 70px;
	position: absolute;
	top: -70px;
	left: 10px;
}
.best-seller .third-box .off .off-box2 {
	width: 70px;
	height: 70px;
	position: absolute;
	top: -90px;
	left: 10px;
}
.best-seller .third-box .off .off-box h3 {
	font-size: 16px;
	line-height: 16px;
}
.best-seller .third-box .off .off-box h3:first-child {
	font-size: 21px;
	line-height: 16px;
}
.best-seller .third-box .off {
	justify-content: end;
	padding: 0 40px;
}
.best-seller .third-box .off img {
	width: 40px;
	margin-bottom: -50px;
}
.best-seller .third-box .off {
	padding: 0 10px 30px 0;
}
.best-seller .third-box .content-wrapper {
	text-align: left;
	padding: 15px 15px;
}
.best-seller .third-box .content-wrapper h4 {
	font-size: 20px;
}
.best-seller .third-box .content-wrapper p {
	font-size: 11px;
}
.best-seller .spacer {
	margin: 22px 0px;
}
/* Best Seller Css End Here */

.content-wrapper p {
    font-size: 16px;
    color: #7E7E7E;
    font-family: 'Poppins';
    font-weight: 400;
}

div#trans::before {
    /* background-color: aquamarine; */
    content: '';
    background-color: #45A547;
    position: absolute;
    width: 15%;
    height: 30px;
    right: 11px;
    top: 2px;
    opacity: 0.9;
}

div#trans {
    position: relative;
}

/* Begin of Mega menu css   */
.div-heding h3 {
    font-size: 20px;
    color: #43a447;
    font-family: 'Poppins';
    font-weight: 600;
}

.image-of-drop-down img {
    width: 100%;
    height: 330px;
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
}

.dropdown-menu.show {
    display: block;
    /* padding: 50px 0px; */
}

.div-ul ul {
    list-style: none;
    padding: 0;
    font-size: 14px;
    font-family: 'GT Walsheim Pro Regular';
}

.div-heding {
    padding: 10px 0px;
}
/* End of Mega menu css  */
.sec-drop {
    position: absolute;
    z-index: 9;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    left: 0;
    /* top: 0; */
    /* bottom: 0; */
    padding: 30px;
    
}
.div-ul  li a {
    color: #000000;
    text-decoration: unset;
}

.dropdown.health-drop button {
    font-size: 16px;
    margin-right: 10px;
}
.button_shashke {
    border-top: 2px solid #44a548 !important;
    border-right: 1px solid rgb(220 220 220 / 58%) !important;
    border-left: 1px solid rgb(220 220 220 / 45%) !important;
  
}

.for-up {
    padding-top: 10px;
}

li.for-bold-li {
    font-weight: 700 !important;
}